import { IonPage, IonContent, IonList, IonRow, IonCol, IonToggle} from '@ionic/vue';
import router from '@/router';
import PageHeader from '@/components/page-header';
import EventTimeEdit from '@/components/eventtime-edit';

import {EnumResourceStrings} from '@/constants/enumresourcestrings';
import {StorageEnum} from '@/constants/enums';
import {ProfileEvent} from '@/models/locationcombination';
import LuckFigurePanel from '@/dashboard-page/luckfigure-panel';
import { thumbsUp as thumbsup, thumbsDown as thumbsdown, starOutline as staroutline, star, save } from 'ionicons/icons';
import { addIcons } from 'ionicons';


export default {

    components: {
        IonPage,
        IonContent,
        IonList,
        IonRow,
        IonCol,
        IonToggle,
        PageHeader,
        LuckFigurePanel,
        EventTimeEdit
    },
    setup() {
        addIcons({
            thumbsup,
            thumbsdown,
            star,
            staroutline,
            save
        });
    },
    data() {
        return{
            StorageEnum: StorageEnum,
            labels: {},
            birthLocation: null,
            eventLocation: null,
            birthDate: null,
            eventDate: null,
            eventDateStruct: null,
            maxConcurrentEvents: 0,
            firstMount: true,
            locationCombination: null,
            profileEvent: ProfileEvent,
            useAverages: false,
            canUseAverages: false,
            loadTrigger: 0,
            roundMinutes: false
        }
    },
    inject: ["storageService", "locationCombinationService", "languageService", "licenseService", "utilService", "luckService"],
    computed: {
        isReady: function () {
            return this.birthLocation && this.eventLocation && this.birthDate;
        },
        luckFigurePanel: function () {
            return this.$refs.luckFigurePanel;
        },
        eventTimeLabels: function () {
            return {
                eventTime: this.labels.eventTime
            }
        },
        locationCombinationName: function () {
            return this.locationCombination ? this.locationCombination.name : '';
        },
        eventRemark: function () {
            return (this.profileEvent && this.profileEvent.remark) ? this.profileEvent.remark : '';
        }
    },
    mounted() {
        this.languageService.getText(EnumResourceStrings.Dashboard).then(x => this.labels.dashboard = x);
        this.languageService.getText(EnumResourceStrings.BirthTime).then(x => this.labels.time = x);
        this.languageService.getText(EnumResourceStrings.BirthPlace).then(x => this.labels.birthPlace = x);
        this.languageService.getText(EnumResourceStrings.CurrentLocation).then(x => this.labels.eventLocation = x);
        this.languageService.getText(EnumResourceStrings.Delete).then(x => this.labels.delete = x);
        this.languageService.getText(EnumResourceStrings.GetLuckFigures).then(x => this.labels.GO = x);
        this.languageService.getText(EnumResourceStrings.LuckFigures).then(x => this.labels.luckFigures = x);
        this.languageService.getText(EnumResourceStrings.Averages).then(x => this.labels.averages = x);
        this.languageService.getText(EnumResourceStrings.RoundMinutes).then(x => this.labels.roundMinutes = x);
        this.onInit();
    },

    ionViewDidEnter() {
        console.log('ionViewDidEnter', this.firstMount);
        if (this.firstMount) {
            this.firstMount = false;
        } else {
            this.onInit();
        }

    },

    methods: {
        onInit: function () {
            this.loadTrigger++;
            this.luckFigurePanel.clear();
            var promises = [];
            promises.push(this.locationCombinationService.getSelectedLocationCombination().then(locationCombination => {//                              
                if (locationCombination) {
                    this.locationCombination = locationCombination;
                } else {
                    return this.storageService.storageGet("Locationcombinations").then(locationCombinations => {
                        if (locationCombinations && locationCombinations.length > 0) {
                            this.locationCombination = locationCombinations[0];
                            return this.locationCombinationService.setSelectedLocationCombination(this.locationCombination);
                        }
                    });
                }
            }));

            promises.push(this.locationCombinationService.getSelectedEvent().then(profileEvent => {//                              
                this.profileEvent = profileEvent;
            }));

            promises.push(this.loadLicense());

            Promise.all(promises).then(() => {
                var subPromises = [];

                subPromises.push(this.storageService.getLocation(StorageEnum.Birth).then(location => {
                    if (location != null) {
                        this.birthLocation = location.name;
                    }
                }));
                subPromises.push(this.storageService.getLocation(StorageEnum.Event).then(location => {
                    if (location != null) {
                        this.eventLocation = location.name;
                    }
                }));
                subPromises.push(this.storageService.getDate(StorageEnum.Birth).then(time => {
                    if (time != null) {
                        this.birthDate = new Date(time.year, time.month, time.day, time.hour, time.minute, time.second, time.milliSecond);
                    }
                }));

                subPromises.push(this.storageService.getDate(StorageEnum.Event).then(dateStruct => {
                    this.eventDateStruct = dateStruct;
                    if (dateStruct != null) {
                        this.eventDate = new Date(dateStruct.year, dateStruct.month, dateStruct.day, dateStruct.hour, dateStruct.minute, dateStruct.second, dateStruct.milliSecond);
                    } else {
                        this.eventDate = null;
                    }
                }));
                return Promise.all(subPromises);
            });
        },
        formatDate: function (value) {
            return this.utilService.formatDate(value);
        },
        loadLicense: function () {
            return this.licenseService.getLicense().then(x => {//             
                this.maxConcurrentEvents = x.license.maxConcurrentEvents;
                this.canUseAverages = x.license.propertiesVm.canUseAverages;
                //                  this.drillDownDepth = x.license.propertiesVm.drillDownDepth;       
            });
        },

        clearEventTime: function (evt) {
            this.eventDate = null;
            this.eventDateStruct = null;
            this.luckFigurePanel.getLuckFigure();
            if (evt) {
                evt.stopPropagation();
            }
        },
        onChangeEventTime: function (dateStruct) {
            console.log('onChangeEventTime', dateStruct);
            this.eventDateStruct = dateStruct;
            this.eventDate = dateStruct ? new Date(dateStruct.year, dateStruct.month, dateStruct.day, dateStruct.hour, dateStruct.minute, dateStruct.second, dateStruct.milliSecond) : null;
            this.getLuckFigure();
        },

        edit: function () {
            return this.storageService.getDate(StorageEnum.Event).then(eventDate => {
                if (eventDate) {
                    this.storageService.storeInMemory(StorageEnum.DrillToSelectedEvent, true);
                    router.replace('/tabs/events');
                } else {
                    router.replace('/tabs/profiles');
                }
            });
        },
        getLuckFigure: function () {
            this.luckFigurePanel.getLuckFigure();
        }
    }
}