<ion-page>
    <page-header :loadTrigger='loadTrigger'></page-header>
    <ion-content :fullscreen="true" class="luck-figures-content">                
        <ion-list>
            <ion-row class="pointer" v-if="this.locationCombinationName">      
                <ion-col @click="edit">
                    <h3 v-text="this.locationCombinationName"></h3>
                    <i v-html="eventRemark"></i>
                </ion-col>
                <ion-col size="6">                    
                    <div v-if="canUseAverages && isReady" class="float-left">
                        <ion-toggle v-model="useAverages" @ionChange="getLuckFigure"></ion-toggle>
                    </div>
                    <div v-if="canUseAverages && isReady" v-html="labels.averages"  class="float-left pad8-top"></div>                    
                    
                    <div v-if="isReady" class="float-left">
                        <ion-toggle v-model="roundMinutes" @ionChange="getLuckFigure"></ion-toggle>
                    </div>
                    <div v-html="labels.roundMinutes"  class="float-left pad8-top"></div>                    
                </ion-col>                
            </ion-row>            
            <ion-row class="pointer" @click="edit">
                <ion-col size="6" v-if="!this.birthLocation" >
                    <span v-html="labels.birthPlace"></span>
                </ion-col>
                <ion-col size="6" v-if="this.birthLocation">
                    <span v-html="this.birthLocation"></span>
                </ion-col>
                <ion-col size="6" v-if="!this.birthDate" v-html="labels.time"></ion-col>
                <ion-col size="6" v-if="this.birthDate">
                    <span class="float-left width120" v-html="formatDate(birthDate)"></span>
                </ion-col>
            </ion-row>
            <ion-row class="pointer">
                <ion-col size="6" @click="edit" v-if="!this.eventLocation">
                    <span v-html="labels.eventLocation"></span>
                </ion-col>
                <ion-col size="6" @click="edit" v-if="this.eventLocation">
                    <span v-html="this.eventLocation"></span>
                </ion-col>  
                <ion-col size="6">
                    <event-time-edit
                        :eventDate="eventDate"
                        @onChange="onChangeEventTime"
                        @onDelete="clearEventTime"
                        :labels="eventTimeLabels"
                        ></event-time-edit>
                </ion-col>
            </ion-row>            
        </ion-list>
        <luck-figure-panel ref="luckFigurePanel" :isReadyParam="isReady" :useAverages="useAverages" 
                           :roundMinutes="roundMinutes"
                           :locationCombination="locationCombination" 
                           :eventDateStructParam="eventDateStruct">

        </luck-figure-panel>

    </ion-content>
</ion-page>